import React, { useState } from 'react'
import Topbar from '../components/Header/Topbar'
import HeroSectionProefabonnement from '../components3/HeroSectionProefabonnement'
import Footer from "../components/Footer/Footer"
const Proefabonnement = () => {
  const [steps, setSteps] = useState(1)
  
  return (
    <>
    <div className='heroSecRow'>
            <Topbar setSteps={setSteps} />
  <HeroSectionProefabonnement steps={steps} setSteps={setSteps}/>
  <Footer/>
          </div>
          </>
  )
}

export default Proefabonnement
