import firebase from "../../config/firebase";

export const getActiveUser =
  (onSuccess = () => {}) =>
  async (dispatch) => {
    // console.log(payload, 'payload');
    try {
      await firebase
        .firestore()
        .collection("maxMagazineActiveCounts")
        .onSnapshot((querySnapshot) => {
          // console.log(querySnapshot.docs[0].data(), "data<<");

          dispatch({
            type: "ACTIVE_USERS",
            payload: querySnapshot.docs[0].data(),
          });
          onSuccess(querySnapshot.docs[0].data());
          // console.log(filteredPackages, "filteredPackages");
        });
    } catch (error) {
      console.log(error);
    }
  };

export const setActiveUser =
  (onSuccess = () => {}) =>
  async (dispatch) => {
    // console.log(payload, 'payload');
    try {
      if (localStorage.getItem("uniqueId") == null) {
        let newDocRef = await firebase
          .firestore()
          .collection("maxMagazineActiveUsers")
          .doc();
        const uniqueId = newDocRef.id;
        localStorage.setItem("uniqueId", uniqueId);
      }
      let uniqueUser = localStorage.getItem("uniqueId");
      firebase
        .firestore()
        .collection("maxMagazineActiveUsers")
        .doc(uniqueUser)
        .set({
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
      console.log(error);
    }
  };
