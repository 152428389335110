import React from 'react';
import { Container, Row } from 'reactstrap';

function PromotionBar() {
	return (
		<Container
			fluid
			className='d-flex justify-content-center bg-yellow promotion-bar'
		>
			<div className='pt-2'>
				<p className='fw--700  text-promotion-text promotion-bar_promotion-text align-self-center'>
					Maak gratis kennis met MAX magazine
				</p>
			</div>
		</Container>
	);
}

export default PromotionBar;
