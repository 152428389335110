import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  FormFeedback,
  FormText,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import Badge2 from "../assets/img/Badge.png";
import arrowup from "../assets/img/up-arrow (5).png";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import rightArrow from "../assets/img/rightArrow.png";
import { postLead, setErrorResponse } from "../store/actions/postLeadAction";
import { getStreet } from "../store/actions/newStraatAction";
import ThankYes from "./ThankYes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormReg = () => {
  const dispatch = useDispatch();
  const location = useLocation().search;
  // const [dob, setDob] = useState(null);
  const [bootCheck, setBootCheck] = useState(false);
  const [addState, setAddState] = useState(false);
  const publisher_id = new URLSearchParams(location).get("site");
  const site_subid = new URLSearchParams(location).get("oa_id");
  // const formattedDate = new Date(dob).toLocaleDateString("en-CA");
  const { create_response, loading, error_response } = useSelector(
    (state) => state.response
  );
  let { newStreet, error_newStreet } = useSelector((state) => state.strait);
  console.log(newStreet, "newStreet");
  const [formData, setFormData] = useState({
    voornaam: "",
    achternaam: "",
    email: "",
    telefoonnummer: "",
    // currYear: "",
    // currMonth: "",
    // currDay: "",
    postcode: "",
    huisnr: "",
    straatnaam: "",
    woonplaats: "",
  });
  const nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
  const streetRegex = /^[a-zA-Z ]*$/;
  const houseRegex = /^[0-9A-Za-z\s-]+$/;
  const phoneRegex = /^(0|\+?31|00\s?31)([-\s]?\d[-\s]?){7,8}\d$/;
  const emailRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/;
  const zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;

  // const days = [];
  // for (let i = 1; i <= 31; i++) {
  //   days.push(i);
  // }

  // const months = [
  //   { label: "Jan", value: "01" },
  //   { label: "Feb", value: "02" },
  //   { label: "Mar", value: "03" },
  //   { label: "Apr", value: "04" },
  //   { label: "May", value: "05" },
  //   { label: "Jun", value: "06" },
  //   { label: "Jul", value: "07" },
  //   { label: "Aug", value: "08" },
  //   { label: "Sep", value: "09" },
  //   { label: "Oct", value: "10" },
  //   { label: "Nov", value: "11" },
  //   { label: "Dec", value: "12" },
  // ];

  // const years = [];
  // const currentYear = new Date().getFullYear();
  // for (let i = currentYear; i >= currentYear - 100; i--) {
  //   years.push(i);
  // }

  // const dob =
  //   formData.currYear +
  //   "-" +
  //   formData.currMonth +
  //   "-" +
  //   ("0" + formData.currDay).slice(-2);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("onchange", e.target.value, name, value);

    setFormData((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  useEffect(() => {
    if (newStreet) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        straatnaam: newStreet?.street,
        woonplaats: newStreet?.settlement,
      }));
    }
  }, [newStreet]);
  const [streetLoader, setStreetLoader] = useState(false);

  useEffect(() => {
    const houseNumberWithoutPunctuation = formData.huisnr.replace(/[\s-]/g, "");

    let obj = {
      zip: formData.postcode,
      house_number: houseNumberWithoutPunctuation,
    };
    if (
      houseRegex.test(formData?.huisnr) &&
      zipRegex.test(formData?.postcode)
    ) {
      setStreetLoader(true);
      dispatch(
        getStreet(obj, setAddState, () => {
          setStreetLoader(false);
        })
      );
    }
  }, [formData?.postcode, formData?.huisnr]);

  const handleFormData = (e) => {
    e.preventDefault();
    const houseNumberWithoutPunctuation = formData.huisnr.replace(/[\s-]/g, "");
    const phoneNumberWithoutPunctuation = formData.telefoonnummer
      .replace(/[\s-]/g, "")
      .replace(/^00(\d+)$/, "$1");

    let obj = {
      language: "nl_NL",
      firstname: formData?.voornaam,
      lastname: formData?.achternaam,
      email: formData?.email,
      // date_of_birth: dob,
      street: formData?.straatnaam,
      house_number: formData?.huisnr,
      zip: formData?.postcode,
      phone_number: phoneNumberWithoutPunctuation,
      city: formData?.woonplaats,
      answers: ["3274"],
      site_subid,
      publisher_id,
      site_custom_name: "onderzoek_1",
      site_custom_url: "www.maxmagazine.gratismeedoen.nl/landelijktvonderzoek",
      bootCheck: bootCheck,
    };
    if (
      streetRegex.test(formData?.woonplaats) &&
      nameRegex.test(formData?.voornaam) &&
      nameRegex.test(formData?.achternaam) &&
      emailRegex.test(formData?.email) &&
      zipRegex.test(formData?.postcode) &&
      houseRegex.test(formData?.huisnr) &&
      phoneRegex.test(formData?.telefoonnummer) &&
      streetRegex.test(formData?.straatnaam)
    ) {
      console.log("last obj", obj);
      dispatch(postLead(obj));
      // console.log(obj, new Date(dob).toLocaleDateString("en-CA"));
    } else {
      console.log("else last obj", obj);
    }
  };
  return (
    <Col className=" h-auto overflow-visible px-0 hero-contents__bg d-flex flex-column align-items-center">
      <Row className="w-100 py-0 mx-0 mb-0 h-100">
        <Col lg={2} className="d-none d-xl-block d-lg-block d-xxl-block"></Col>{" "}
        <Col
          lg={6}
          className="mt-md-5 mt-0 px-lg-0 px-5 order-md-2 order-1  position-relative "
        >
          {/* <div className="mx-3">
            <h3 className="mb-0 fs--22">
              Bedankt voor uw deelname aan het
              <span className="text-site-yellow mb-0 fw--700 ml-1">
                Landelijk TV Onderzoek 2023!
              </span>
            </h3>

            <p className="text_right">
              mede mogelijk gemaakt door MAX Magazine
            </p>
          </div>
          <div className="mx-3 mt-4">
            <h4 className="mb-0">
              We hebben voor elke deelnemer van het{" "}
              <span className="text-site-yellow fw--700">
                Landelijk TV Onderzoek 2023
              </span>{" "}
              een gratis proefnummer beschikbaar van MAX Magazine.
              <div className="d-flex justify-content-between d-sm-none d-block ">
                {" "}
                <h4>
                  Ook maakt u nu kans op een bol.com cadeaukaart t.w.v. €20,-
                </h4>
                <img
                  src={Badge2}
                  className="img-fluid"
                  height={200}
                  width={150}
                />
              </div>
              <h4 className="d-sm-flex d-none ">
                Ook maakt u nu kans op een bol.com cadeaukaart t.w.v. €20,-
                <img
                  src={arrowup}
                  className="position- arrow-up d-sm-block d-none"
                  height="auto"
                  width="auto"
                />
              </h4>
            </h4>
          </div> */}

          <div className="ml-xl-3 ml-5 mt- d-flex justify-content-center d-sm-block d-none   position-relative">
            <img
              src={Badge2}
              className="img-fluid d-sm-block d-none ml-auto mr-n5 badge-sm"
              height={400}
              width={350}
            />{" "}
          </div>
        </Col>
        <Col className="px-md-0 pr- mx-md-0 mx-auto order-md-1 order-2">
          <Container className="postion-relative h-100  d-flex flex-column justify-content-end px-0">
            {create_response ? (
              <ThankYes create_response={create_response} />
            ) : (
              <Form
                onSubmit={handleFormData}
                className="shadow p-4   text-site-secondary  position-absolute bg-white rounded mr-md-n5 mr-0 mb-5  form-style"
              >
                <h5 className="fw--700 w-100 text-center mb-4">
                  Bedankt voor uw deelname.
                  <br></br>U krijgt een gratis proefnummer van MAX en u maakt
                  kans op een bol.com bon t.w.v. €20,-
                  <br></br>{" "}
                  <span className="font-weight-normal">
                    Naar wie mogen wij het sturen?
                  </span>
                </h5>

                <FormGroup>
                  <Input
                    autoComplete="true"
                    required
                    name="voornaam"
                    placeholder="Voornaam"
                    className="bg-site-gray custom-input"
                    onChange={(e) => {
                      handleInputChange(e);
                      error_response?.firstname && dispatch(setErrorResponse());
                    }}
                    invalid={
                      (formData?.voornaam &&
                        !nameRegex.test(formData?.voornaam)) ||
                      error_response?.firstname
                    }
                    // valid={nameRegex.test(formData?.voornaam)}
                  />
                  <FormFeedback>Voornaam niet correct</FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Input
                    autoComplete="true"
                    required
                    name="achternaam"
                    placeholder="Achternaam"
                    className="bg-site-gray custom-input"
                    onChange={(e) => {
                      handleInputChange(e);
                      error_response?.lastname && dispatch(setErrorResponse());
                    }}
                    invalid={
                      (formData?.achternaam &&
                        !nameRegex.test(formData?.achternaam)) ||
                      error_response?.lastname
                    }
                    // valid={nameRegex.test(formData?.achternaam)}
                  />
                  <FormFeedback>Achternaam niet correct</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    autoComplete="true"
                    name="email"
                    required
                    placeholder="E-mailadres"
                    className="bg-site-gray custom-input"
                    onChange={(e) => {
                      handleInputChange(e);
                      error_response?.email && dispatch(setErrorResponse());
                    }}
                    invalid={
                      (formData?.email && !emailRegex.test(formData?.email)) ||
                      error_response?.email
                    }
                    // valid={emailRegex.test(formData?.email)}
                  />
                  <FormFeedback>E-mailadres niet correct</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Input
                    autoComplete="true"
                    required
                    name="telefoonnummer"
                    placeholder="Telefoonnummer"
                    className="bg-site-gray number-input custom-input"
                    onChange={(e) => {
                      handleInputChange(e);
                      error_response?.phone_number &&
                        dispatch(setErrorResponse());
                    }}
                    invalid={
                      (formData?.telefoonnummer &&
                        !phoneRegex.test(formData?.telefoonnummer)) ||
                      error_response?.phone_number
                    }
                    // valid={phoneRegex.test(formData?.telefoonnummer)}
                  />
                  <FormFeedback>Telefoonnummer niet correct</FormFeedback>
                </FormGroup>

                {/* <FormGroup>
                  <DatePicker
                    selected={dob}
                    onChange={(date) => setDob(date)}
                    dateFormat="yyyy/MM/dd"
                    placeholderText={"yyyy/MM/dd"}
                    // type="date"
                    // name="dob"
                    // className="bg-site-gray number-input custom-input"
                    className="form-control bg-site-gray registerationForm_inputflieds"
                    // onChange={handleInputChange}
                  />
                </FormGroup> */}

                {/* <Row noGutters className="justify-content-between">
                  <Col xs="12" className="mt-">
                    <Label className=" ml-2 fs--14 fw--500">
                      Geboortedatum
                    </Label>
                  </Col>

                  <Col xs="3">
                    <FormGroup>
                      <Input
                        type="select"
                        placeholder="Dag"
                        name="currDay"
                        className="bg-site-gray number-input custom-input"
                        value={formData.currDay}
                        onChange={(e) => handleInputChange(e)}
                        required
                      >
                        <option value="" disabled>
                          Dag
                        </option>
                        {days?.map((day, index) => {
                          return (
                            <option value={day} key={index}>
                              {day}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Input
                        type="select"
                        placeholder="Maand"
                        name="currMonth"
                        className="bg-site-gray number-input custom-input"
                        value={formData.currMonth}
                        onChange={
                          (e) => handleInputChange(e)
                        }
                        required
                      >
                        <option value="" default disabled>
                          Maand
                        </option>
                        {months?.map((mon, index) => {
                          return (
                            <option value={mon.value} key={index}>
                              {mon.label}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Input
                        type="select"
                        placeholder="Dag"
                        name="currYear"
                        className="bg-site-gray number-input custom-input"
                        value={formData.currYear}
                        onChange={(e) => handleInputChange(e)}
                        required
                      >
                        <option value="" disabled>
                          Jaar
                        </option>
                        {years?.map((year, index) => {
                          return (
                            <option value={year} key={index}>
                              {year}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row> */}

                <Row>
                  <Col className="pr-md-0" md="6">
                    {" "}
                    <FormGroup>
                      <Input
                        autoComplete="true"
                        name="postcode"
                        required
                        placeholder="Postcode"
                        className="bg-site-gray custom-input"
                        onChange={(e) => {
                          handleInputChange(e);
                          error_response?.zip && dispatch(setErrorResponse());
                        }}
                        invalid={
                          (formData?.postcode &&
                            !zipRegex.test(formData?.postcode)) ||
                          error_newStreet?.zip ||
                          error_response?.zip
                        }
                        // valid={zipRegex.test(formData?.postcode)}
                      />
                      {/* {<Spinner color="primary" size="sm" />} */}
                      <FormFeedback>Postcode niet correct</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className={`${streetLoader ? "col-5" : "col-6"}`}>
                    {" "}
                    <FormGroup>
                      <Input
                        name="huisnr"
                        required
                        placeholder="Huisnr."
                        className="bg-site-gray custom-input"
                        onChange={(e) => {
                          handleInputChange(e);
                          error_response?.housenumber &&
                            dispatch(setErrorResponse());
                        }}
                        invalid={
                          (formData?.huisnr &&
                            !houseRegex.test(formData?.huisnr)) ||
                          error_newStreet?.zip ||
                          error_response?.housenumber
                        }
                        //  // valid={houseRegex.test(formData?.huisnr)}
                      />
                      <FormFeedback>Huisnummer niet correct</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className={`${streetLoader ? "col-1" : "d-none"}`}>
                    <div
                      className="d-flex
    justify-content-end pt-2"
                    >
                      {streetLoader && (
                        <div>
                          <Spinner color="primary" size="sm" />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                {/* <FormGroup>
                  <Input
                    name="straatnaam"
                    placeholder="Straatnaam"
                    className="bg-site-gray custom-input"
                    value={formData?.straatnaam}
                    onChange={handleInputChange}
                    invalid={
                      formData?.straatnaam &&
                      !streetRegex.test(formData?.straatnaam)
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="woonplaats"
                    placeholder="Woonplaats"
                    className="bg-site-gray custom-input"
                    value={formData?.woonplaats}
                    onChange={handleInputChange}
                    invalid={
                      formData?.woonplaats &&
                      !streetRegex.test(formData?.woonplaats)
                    }
                  />
                </FormGroup> */}
                {addState ? (
                  <small className="text-danger">Adres is niet correct</small>
                ) : (
                  ""
                )}
                <Input
                  type="checkbox"
                  value={bootCheck}
                  onChange={() => setBootCheck(!bootCheck)}
                  style={{ opacity: "0.001" }}
                />
                <Button
                  color="yellow"
                  type="submit"
                  className="w-100 form-button py-2"
                  disabled={
                    !newStreet ||
                    error_newStreet?.zip ||
                    streetLoader ||
                    addState
                  }
                >
                  {loading ? (
                    <Spinner size="md" color="white" className="text-center" />
                  ) : (
                    <div className="d-flex justify-content-between align-items-center mx-2">
                      <span></span>
                      <span className="align-self-center">
                        Bevestig deelname
                      </span>
                      <span className="ml-lg-5 ml-xs-0 mr-0 mr-0 align-self-center">
                        <i className="fas fa-arrow-right text-white"></i>
                      </span>
                    </div>
                  )}
                </Button>

                <h6 className="fw--500 fs--12 my-3 text-center">
                  {/* *Door op "bevestig deelname"te klikken, gaat u er mee akkoord
                  dat MAX Magazine u eenmalig mag benaderen met een passende
                  aanbieding. Er is 1 proefnummer beschikbaar per adres. */}
                  Door op "bevestig deelname" te klikken, gaat u er mee akkoord
                  dat MAX Magazine u eenmalig mag benaderen met een passende
                  aanbieding. U zit nergens aan vast! Er is 1 proefnummer
                  beschikbaar per adres.
                </h6>
              </Form>
            )}
          </Container>
        </Col>{" "}
      </Row>
    </Col>
  );
};
export default FormReg;
