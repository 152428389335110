import React from "react";
// import { Container, Row, Col } from 'react-bootstrap';
import { Col, Container, Row } from "reactstrap";

import magazne from "../assets/img/footerbeeldmagazines.png";
import magaznecover from "../assets/img/magazine_cover kopie 3.png";

function MainViewContent2({ step }) {
  const data = [
    "Actuele artikelen over gezondheid, financién en levensvragen",
    "Aangrijpende en waargebeurde verhalen",
    "Tips voor dagjes uit, wonen en het huishouden",
    "Leuke puzzels en win acties",
    "De meeste pagina's voor de laagste prijs",
  ];

  if (step == 3) {
    return (
      <Container fluid className="bg-site-pink py-5">
        <Container>
          <Row className="">
            <Col md={8}>
              <div className="d-flex justify-content-center">
                <h5 className="fw--700 text-site-primary">
                  Naast het super overzichtelijke televisie en radio
                  programmaoverzicht bevat MAX Magazine wekelijks:
                </h5>
              </div>

              <div className="d-flex flex-column justify-content-center my-3">
                {data?.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className="d-flex align-items-center mb-3"
                    >
                      <i className="fas fa-arrow-right text-primary"></i>
                      <h6 className="mb-0 ml-1">{item}</h6>
                    </span>
                  );
                })}{" "}
              </div>
            </Col>
            <Col>
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img src={magaznecover} className="img-fluid"></img>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  } else {
    return (
      <Container>
        <Row className="">
          <Col>
            <div className="d-flex justify-content-center">
              <img
                src={magazne}
                className="img-fluid"
                // height={400}
                width={800}
              ></img>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MainViewContent2;
