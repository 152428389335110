const initialState = {
  newStreet: null,
  error_newStreet: null,
  houseNumber:""
};
const newStraatReducers = (state = initialState, action) => {
  // console.log(action, 'action');
  switch (action.type) {
    case "NEW_STRAAT":
      return {
        ...state,
        newStreet: action.payload,
      };
      case "SETT_HOUSE_NUMBER":
        return {
          ...state,
          houseNumber:action.payload
        };
    case "STREET_ERROR_RESPONSE":
      return {
        ...state,
        error_newStreet: action.payload,
      };
    default:
      return state;
  }
};
export default newStraatReducers;
