import React from "react";
import { Container, Col, Row } from "reactstrap";
import Max_maginze from "../../assets/img/max_magazne-logo2.png";
function Header() {
  return (
    <Container fluid>
      {/* /////////////////////////// */}
      <Container className="py-2">
        <Row className="header">
          <Col md={1}></Col>
          <Col>
            <div>
              <img
                src={Max_maginze}
                alt=""
                className="img-fluid "
                width={200}
              />
            </div>
          </Col>
          <Col>
            {" "}
            <div className=" d-flex justify-content-end align-items-center h-100 w-100">
              <span className=" fw--700  mb-0 ">1000 deelnemers gezocht! </span>
            </div>
          </Col>
        </Row>
      </Container>
      {/* ////////////////////////////////////// */}
      {/* <Row className="d-flex  align-items-center header py-md-0 py-2">
        <Col md={8} sm={3} className="m-auto  bg-danger">
          <Row noGutters>
            <Col></Col>
            <Col lg={9}>
              <div className="d-flex align-items-center justify-content-start header__logo my-md-2 my-0">
                <div>
                  <img
                    src={Max_maginze}
                    alt=""
                    className="img-fuild"
                    width={200}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={4} sm={3} className="header__main">
          <span className="mt-4 fw--700 header__tag">
            1000 deelnemers gezocht!{" "}
          </span>
        </Col>
      </Row> */}
    </Container>
  );
}
export default Header;
