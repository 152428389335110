import React from "react";
// import { Container, Row, Col } from 'react-bootstrap';
import { Button, Card, Col, Container, Row } from "reactstrap";
import ovaal from "../assets/img/Ovaal 5 kopie 5.png";
import promotion_img from "../assets/img/NewBadge.png";
import magazne from "../assets/img/magazine_cover kopie 3.png";
import magazine1 from "../assets/img/bottom-Image.png";
import magazine2 from "../assets/img/bottom-image2.png";
import GreenTick from "../assets/img/g52-9-5.svg";
let data = [
  "Actuele artikelen over gezondheid, financiën en levensvragen",
  "Aangrijpende en waargebeurde verhalen",
  "Tips voor dagjes uit, wonen en het huishouden",
  "Leuke puzzels en winacties",
];
function MainViewContent1() {
  return (
    // <Container className="pb-5">
    //   <Row>
    //     <Col lg="6">
    //       <div className="my-5">
    //         <h4 className="fw--700">
    //           Naast het super overzichtelijke televisie en radio
    //           programmaoverzicht bevat MAX Magazine wekelijks:
    //         </h4>
    //       </div>

    //       <div className="my-5">
    //         <div className="d-flex ">
    //           <div>
    //             {" "}
    //             <img src={ovaal} width={10}></img>
    //           </div>

    //           <p className="w-75 ml-3">
    //             Actuele artikelen over gezondheid,<br></br> financiën en
    //             levensvragen
    //           </p>
    //         </div>
    //         <div className="d-flex ">
    //           <div>
    //             <img src={ovaal} width={10}></img>
    //           </div>

    //           <p className="w-75 ml-3">Aangrijpende en waargebeurde verhalen</p>
    //         </div>
    //         <div className="d-flex ">
    //           <div>
    //             <img src={ovaal} width={10}></img>
    //           </div>
    //           <p className="w-75 ml-3">
    //             Tips voor dagjes uit, wonen en het huishouden
    //           </p>
    //         </div>
    //         <div className="d-flex ">
    //           <div>
    //             <img src={ovaal} width={10}></img>
    //           </div>
    //           <p className="w-75 ml-3 ">Leuke puzzels en winacties</p>
    //         </div>
    //         <div className="d-flex ">
    //           <div>
    //             <img src={ovaal} width={10}></img>
    //           </div>
    //           <p className="w-75 ml-3 ">
    //             De meeste pagina’s voor de laagste prijs
    //           </p>
    //         </div>
    //       </div>
    //     </Col>
    //     <Col lg="6">
    //       <div>
    //         <img
    //           src={promotion_img}
    //           className="MainContent1_img-promotion img-fluid"
    //         ></img>
    //         <img
    //           src={magazne}
    //           height="500px"
    //           width="600px"
    //           className="mt-5 ml-lg-n5 img-fluid"
    //         ></img>
    //       </div>
    //     </Col>
    //   </Row>
    // </Container>

    <div>
      <Card className="bottomSection">
        <Row className="row justify-content-center">
          <div className="bottomLeftCol  img-fluid img-md-fixed  col-md-6 order-md-1 order-2">
            <img
              src={magazine1}
              alt="img"
              className="img-fluid magazine1 d-md-block d-none"
            />
            <img
              src={magazine2}
              alt="img"
              className="img-fluid magazine2 d-md-none d-block"
            />
          </div>
          <div className="BottomRightCol col-md-6 order-md-2 order-1">
            <p className="my-4 mx-4">
              Naast het super overzichtelijke televisie- en radio
              programmaoverzicht <b>bevat MAX Magazine wekelijks:</b>
            </p>
            {/* <h6 className="mx-4">Boordevol:</h6> */}
            {data.map((e) => {
              return (
                <Card className="py-4 px-3 my-3 mx-4 cards home_card_one shadow">
                  <h5 className="d-flex mb-0 align-items-center">
                    <img src={GreenTick} className="mr-4 GreenTick" />
                    <b>{e}</b>
                  </h5>
                </Card>
              );
            })}

            <div
              className="text-center my-5  ml-4 d-md-none d-flex mr-auto     justify-content-center
"
            >
              <a
                href="#addPostCode"
                className=" btn btn-warning"
                // color="warning"
              >
                <span className="large-screen-text">
                  Controleer nu uw postcode
                </span>
                <i className="fa fa-solid fa-chevron-up mx-3"></i>{" "}
              </a>
            </div>
          </div>
        </Row>
        <div className="text-center my-3 d-md-block d-none">
          <a
            href="#addPostCode"
            className=" btn btn-warning"

            // color="warning"
          >
            <span className="large-screen-text">Controleer nu uw postcode</span>
            <i className="fa fa-solid fa-chevron-up mx-3"></i>{" "}
          </a>
        </div>
      </Card>
    </div>
  );
}

export default MainViewContent1;
