import Repository from "./Repostory";

// const repositories = {
// 	repo: Repository,
// };
// // eslint-disable-next-line import/prefer-default-export
// export const RepositoryFactory = {
// 	get: name => repositories[name],
// };

import StreetRepository from "./StreetRepository";

const repositories = {
	repo: Repository,
};
// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
  get: (name) => repositories[name],
};
