import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Col, Row, Card } from "reactstrap";

// import img from "../assets/img/uiEightImage.png";

function ThankYes({ create_response }) {
  let year = new Date().getFullYear();
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (create_response?.profile?.id) {
      setImageUrl(
        `https://republish.gratismeedoen.nl/m/5738/7b24f7176dba/?event=6701&unique_conversion_id=${create_response?.profile?.id}`
      );
    }
  }, [create_response?.profile?.id]);
  return (
    <Card className="shahow-sm p-4 text-site-secondar position-absolute bg-white rounded  mb-5  form-style">
      <h1 className="justify-content-center thanks-contents__thankNo align-items-center text-center">
        Bedankt voor je deelname aan het Landelijk TV Onderzoek {year}!{" "}
      </h1>

      <span className="thanks-contents__thankNo-text text-center">
        Wij nemen zo snel mogelijk contact met je op over het te ontvangen
        proefnummer.
      </span>

      {imageUrl && (
        <img
          referrerpolicy="no-referrer-when-downgrade"
          src={imageUrl}
          style={{
            width: "100px",
            height: "100px",
            border: "0px",
          }}
        />
      )}
    </Card>
  );
}

export default ThankYes;
