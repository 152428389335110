// import PromotionBar from "../components/Max-Maginze_1/PromotionBar";
// import MainViewContent1 from "../components/MainViewContent1";
import Header from "../components2/Header/Topbar";
import HeroSection from "../components2/Max-Maginze_2/HeroSection";
import { useEffect, useState } from "react";
import Footer from "../components2/Footer/Footer";
import PromotionBar from "../components2/Max-Maginze_2/PromotionBar";
import MainViewContent2 from "../components2/MainViewContent2";
import UITwo from "../components2/Max-Maginze_2/UITwo";
import { useSelector } from "react-redux";

// import UITwo from "../components2/UITwo";

const Onderzoek = (props) => {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const { create_response } = useSelector((state) => state.response);

  useEffect(() => {
    setTimeout(() => {
      if (create_response?.profile || create_response?.profile == true) {
        let useScript = document.createElement("script");
        useScript.setAttribute(
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          ),
          window.fbq("init", "382090309408938"),
          window.fbq("track", "PageView"),
          window.fbq("track", "Lead")
        );
        document.head.appendChild(useScript);
      } else {
        let useScript = document.createElement("script");
        useScript.setAttribute(
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          ),
          window.fbq("init", "382090309408938"),
          window.fbq("track", "PageView")
        );
        document.head.appendChild(useScript);
      }
    }, 4000);
  }, [create_response]);
  return (
    <>
      <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=382090309408938&ev=PageView&noscript=1"
        />
      </noscript>
      <div className="bg-white popinsFont">
        <Header />
        {/* <HeroSection /> */}
        {step == 0 && <HeroSection step={step} setStep={setStep} />}
        {step > 0 && <UITwo step={step} setStep={setStep} />}
        <PromotionBar />
        <MainViewContent2 step={step}></MainViewContent2>

        <Footer />
      </div>
    </>
  );
};

export default Onderzoek;
