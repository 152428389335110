import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import HeroSection from "../components/Max-Maginze_1/HeroSection";
import PromotionBar from "../components/Max-Maginze_1/PromotionBar";
import MainViewContent1 from "../components/MainViewContent1";
import Footer from "../components/Footer/Footer";
import TopBar from "../components/Header/Topbar";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const AdminView = (props) => {
  const { create_response, error_response } = useSelector(
    (state) => state.response
  );
  console.log("==========>", error_response);
  useEffect(() => {
    setTimeout(() => {
      if (create_response?.profile) {
        // alert("asd");
        let useScript = document.createElement("script");
        useScript.setAttribute(
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          ),
          window.fbq("init", "382090309408938"),
          window.fbq("track", "PageView"),
          window.fbq("track", "Lead")
        );
        document.head.appendChild(useScript);
      } else {
        // alert("uio");
        let useScript = document.createElement("script");
        useScript.setAttribute(
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          ),
          window.fbq("init", "382090309408938"),
          window.fbq("track", "PageView")
        );
        document.head.appendChild(useScript);
      }
    }, 4000);
  }, [create_response]);
  return (
    <div className="Container">
      <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=382090309408938&ev=PageView&noscript=1"
        />
      </noscript>
      <HeroSection />
      {/* <div className="bg-site-table-color">
        <MainViewContent1></MainViewContent1>
      </div>{" "} */}
      <Footer />
    </div>
  );
};

export default AdminView;
