import React from "react";
import { Container, Row } from "reactstrap";

function PromotionBar() {
  return (
    <Container
      fluid
      className="d-flex justify-content-center bg-white promotion-bar"
    >
      <div className="py-3 d-flex justify-content-centerb align-self-center  ">
        <span className="fs--16 text-center" style={{ color: "#123953" }}>
          Dit onderzoek wordt mede mogelijk gemaakt door MAX Magazine, het
          Grootste Magazine van Nederland en veel meer dan een tv-gids.
        </span>
      </div>
    </Container>
  );
}

export default PromotionBar;
