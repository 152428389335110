import { combineReducers } from 'redux';
import newStraatReducers from './newStraatReducer';
import newLeadReducers from './postLeadReducer';

const rootReducer = combineReducers({
	strait: newStraatReducers,
	response: newLeadReducers,
});

export default rootReducer;
