import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, Spinner } from "reactstrap";
import heroImg from "../../assets/img/heroImg1.png";
import badge from "../../assets/img/Badge.png";
import badgeBol from "../../assets/img/blueBadge.png";
import badgesm from "../../assets/img/Badgesm.png";
import {
  getActiveUser,
  setActiveUser,
} from "../../store/actions/magazineAction";
import { useDispatch } from "react-redux";
// import iVector from "../assets/img/i-vector.png";
// import Arrow from "../../assets/img/Arrow.svg";
function UIOne({ step, setStep }) {
  const dispatch = useDispatch();
  const [users, setUsers] = useState();
  let year = new Date().getFullYear();

  useEffect(() => {
    dispatch(
      getActiveUser((res) => {
        setUsers(res);
      })
    );
    dispatch(setActiveUser());
  }, []);

  return (
    <Container fluid>
      <Row className="hero-contents hero-contents__img">
        <Col
          md={12}
          lg={8}
          xl={7}
          className=" h-auto px-0 hero-contents__bg pt-5 position-relative "
        >
          <Row className="w-100 py-0 mx-0 mb-0">
            <Col md={3}></Col>{" "}
            <Col
              md={8}
              className="mt-sm- mt-0 px-md-0 px-5  d-flex justify-content-center flex-column "
            >
              <h1 className="fw-bold hero-contents__title">
                Welkom bij het
                <br />
                <span className="text-yellow hero-contents__titleY fw--800">
                  Landelijk TV Onderzoek {year}
                </span>{" "}
              </h1>
            </Col>
          </Row>
          <Row className="w-100 px-0 my-0 mx-0 py-0">
            <Col
              md={3}
              className="px-0 mx-0 my-0 py-0 hero-contents__textdiv"
            ></Col>
            <Col className="px-md-0 px-5  mr-md-5 mr-0 my-0 py-3 hero-contents__text ">
              {/* <p className=" py-3 mr-5   fw--700"> */}
              {/* Maakt u nog gebruik van de reguliere TV? */}
              Is uw kijkgedrag veranderd?
              {/* </p> */}
            </Col>
          </Row>
          <Row className=" w-100 py-0 mx-0 mt-2">
            <Col md={3} className="py-0"></Col>{" "}
            <Col
              md={8}
              className="px-md-0 px-5 pt-3 d-flex  justify-content-center flex-column position-relative "
            >
              <div className="d-flex align-items-center">
                <div>
                  <p className="hero-contents__smtext2 fs--15">
                    Wij onderzoeken of het kijkgedrag van Nederlanders veranderd
                    is.{" "}
                    <b>Voor een kort onderzoek zoeken wij 1.000 deelnemers.</b>
                    {/* Mede door de opkomst van verschillende streaming diensten en
                    andere online media.{" "} */}
                  </p>

                  <p className="mt-1 hero-contents__smtext2 fs--15">
                    {/* <b>
                      Wij zijn benieuwd naar het TV gebruik onder de
                      Nederlanders.
                    </b>
                    Voor een kort onderzoek zoeken wij 1000 deelnemers. */}
                    De laatste jaren is het aanbod en de manier van kijken sterk
                    veranderd. Onder andere door de opkomst van streaming
                    diensten en andere online media.
                  </p>
                </div>{" "}
                <img src={badgesm} className="badgeHero d-md-none d-block" />
              </div>
              <small className="ml-0 hero-contents__smtext fw--700  fs--15">
                Voor elke deelnemer van het Landelijk TV Onderzoek {year} ligt
                een gratis proefnummer klaar van MAX Magazine (1 per adres).
                Daarbij maakt iedere deelnemer kans op een bol.com cadeaubon
                t.w.v €20,- !
              </small>
              <Button
                color="green"
                className="hero-contents__btn d-flex justify-content-around fw--600 align-items-center pe-2 ps-4 mt-3"
                onClick={() => {
                  setStep(1);
                }}
              >
                Start deelname
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  //   xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="15px"
                  height="12px"
                >
                  <path
                    fill-rule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M14.621,5.77 L9.973,0.381 C9.469,0.127 8.651,0.127 8.147,0.381 C7.642,0.890 7.642,1.716 8.147,2.226 L10.591,4.695 L1.291,4.695 C0.578,4.695 0.0,5.279 0.0,5.999 C0.0,6.719 0.578,7.304 1.291,7.304 L10.591,7.304 L8.147,9.773 C7.642,10.282 7.642,11.108 8.147,11.617 C8.399,11.872 8.729,12.0 9.60,12.0 C9.390,12.0 9.721,11.872 9.973,11.617 L14.621,6.922 C15.126,6.412 15.126,5.586 14.621,5.77 Z"
                  />
                </svg>{" "}
              </Button>
              <small className="hero-contents__xstext mt-2">
                <b>
                  {users ? users?.activeUsers : <Spinner size={"sm"}></Spinner>}{" "}
                  anderen
                </b>{" "}
                nemen op dit moment deel aan het onderzoek.
              </small>
              <hr />
              <hr />
            </Col>
          </Row>
          {/* pre-work */}
          {/* <Row className="w-100  mx-0 my-">
            <Col md={3}></Col>
            <Col
              md={8}
              className="py-md-5 py-0 px-lg-0 px-sm-5 px-0  d-flex justify-content-center flex-column position-relative "
            >
              <h1 className="fw-bold hero-contents__title">
                Welkom bij het
                <br />
                <span className="text-site-yellow fw--800">
                  Ladnelijk TV Onderzoek 2023
                </span>{" "}
              </h1>
              <div className="position-relative">
                <p className="mt-1 py-3 mr-5 hero-contents__text fw--700">
                  Maakt u nog gebruik van de reguliere TV?
                </p>
              </div>
              <p className="mt-1 hero-contents__smtext2">
                <b>
                  Er is de laatste jaren enorm veel veranderd in het televisie
                  landschap.{" "}
                </b>
                Mede door de opkomst van verschillende streaming diensten en
                andere online media.{" "}
              </p>
              <p className="mt-1 hero-contents__smtext2">
                <b>
                  Wij zijn benieuwd naar het TV gebruik onder de Nederlanders.
                </b>
                Voor een kort onderzoek zoeken wij 1000 deelnemers.
              </p>
              <small className="ml-0 hero-contents__smtext fw--700">
                Voor elke deelnemer van het Landelijk TV Onderzoek 2023 ligt een
                gratis proefnummer klaar van MAX Magazine (1 per adres).
              </small>

              <Button
                color="yellow"
                className="hero-contents__btn d-flex justify-content-around fw--600 align-items-center pe-2 ps-4 mt-3"
                onClick={() => {
                  setStep(1);
                }}
              >
                Start deelname
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  //   xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="15px"
                  height="12px"
                >
                  <path
                    fill-rule="evenodd"
                    fill="rgb(255, 255, 255)"
                    d="M14.621,5.77 L9.973,0.381 C9.469,0.127 8.651,0.127 8.147,0.381 C7.642,0.890 7.642,1.716 8.147,2.226 L10.591,4.695 L1.291,4.695 C0.578,4.695 0.0,5.279 0.0,5.999 C0.0,6.719 0.578,7.304 1.291,7.304 L10.591,7.304 L8.147,9.773 C7.642,10.282 7.642,11.108 8.147,11.617 C8.399,11.872 8.729,12.0 9.60,12.0 C9.390,12.0 9.721,11.872 9.973,11.617 L14.621,6.922 C15.126,6.412 15.126,5.586 14.621,5.77 Z"
                  />
                </svg>{" "}
              </Button>
              <small className="hero-contents__xstext">
                <b>20 anderen</b> nemen op dit moment deel aan het onderzoek.
              </small>
              <hr />
              <hr />
              <img src={badge} className="badgeHero" />
            </Col>
          </Row> */}
          <img
            src={badge}
            className="badgeHero d-md-block d-lg-none d-none img-fluid"
          />

          <div className="bottom-bar mt-auto">
            <Row className="">
              <Col md={3}>
                <img src={badgeBol} className="badgeBol d-md-block d-none" />
              </Col>
              <Col md={7} className="py-md-2 py-3 d-flex px-4">
                <span className="fw--700 mb-0 fs--13 px-md-0 pl-5 pr-0">
                  Naast het gegarandeerde gratis proefnummer, maakt iedere
                  deelnemer ook nog kans op een bol.com cadeaukaart t.w.v €20,-
                </span>
                <img src={badgeBol} className="badgeBol d-md-none d-block" />
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>
        </Col>
        <Col
          md={12}
          lg={4}
          xl={5}
          className="px-0 d-lg-block d-none position-relative right-block"
        >
          {/* <img src={heroImg} alt="" /> */}
          <img src={badge} className="badgeHero img-fluid" />
        </Col>
      </Row>
      <Row className="d-block d-lg-none p-0 image-handle">
        <Col className="p-0">
          <img src={heroImg} className="img-fluid" alt="Hero Image" />
        </Col>
      </Row>
    </Container>
  );
}
export default UIOne;
