import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import MagazinePoster from "../../assets/img/maginzePoster.png";
import image from "../../assets/img/updateHomeImgWithArrow Phone .png";
import image2 from "../../assets/img/updateHomeImgWithArrow.png";
import image3 from "../../assets/img/changePhoneImg.png";
import MaxImage from "../../assets/img/max_mini-logo2.png";
import rightArrow from "../../assets/img/rightArrow.png";
import RegisterationForm from "./RegisterationForm";
import PromotionBar from "./PromotionBar";
import AdvertisementImage from "../../assets/img/NewRightPicture2.png";
import badge from "../../assets/img/bol.com_badge.png";
import { useLocation } from "react-router-dom";
import MainViewContent1 from "../MainViewContent1";
import Topbar from "../Header/Topbar";

function HeroSection() {
  const [active, setActive] = useState(1);
  const [postCode, setPostCode] = useState("");
  let dispatch = useDispatch();

  const RegisterationFormData = (e) => {
    e.preventDefault();
    if (checkZipRegex(postCode) === false) {
      let increment = active + 1;
      setActive(increment);
    }
  };
  const checkZipRegex = (postCode) => {
    let zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
    if (postCode != "") {
      if (zipRegex.test(postCode)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  return (
    <>
      {/* <Container fluid className="bg-white p-0 pb-0 hero-section">
        <Container>
          <Row>
          
            <div className="d-block d-md-none text-right   hero-section_Poster-col_mobile-div">
              <img
                src={MagazinePoster}
                alt="magazinePoster"
                width={"70%"}
                className="hero-section_Poster-col_mobile-div_magazine"
              />
              <img
                src={PersonPicture}
                alt="PersonPicture"
                width={"50%"}
                className="hero-section_Poster-col_mobile-div_person"
              />

              <img
                src={badge}
                alt="PersonPicture"
                className="hero-section_Poster-col_badge d-sm-block d-none"
              />
            </div>
            <Col md="6" className="hero-section_Form-col p-0 p-md-2">
              <Container className=" mt-md-0 px-1  pt-md-0 h-100">
                {active === 1 ? (
                  <Form
                    className="card my-3 my-md-0 mx-sm-0 mx-3"
                    onSubmit={RegisterationFormData}
                  >
                    <Container className="text-left rounded text-md-center px-5  bg-main-container shadow">
                      <Row className=" hero-section_logo-adj ">
                        <div className="pt-2">
                          <h2 className="fs--40 fw--800 mb-n2">
                            Actie! Probeer{" "}
                          </h2>
                          <div className="d-flex text-left text-md-center p-0 mt-1">
                            <img
                              src={MaxImage}
                              alt="MaxImage"
                              width={"80px"}
                            />
                            <h2 className="ml-sm-2  fs--40 fw--700">
                              Magazine
                            </h2>
                          </div>
                          <h2 className="fs--40 fw--800 mt-n3">nu GRATIS</h2>
                        </div>
                        <h3 className="text-success text-center">
                          <b className="fw--800">
                            + maak kans op een <br />
                            bol.com cadeaubon
                          </b>
                        </h3>
                      </Row>
                      <Row className="mt-2 d-flex justify-content-center">
                        <div className="pt-0">
                          <p className="hero-section_Form-col_paragraph fw--600 text-center pt-5 pt-md-0">
                            Check hier of uw postcode in aanmerking komt
                            <br></br>
                            voor een{" "}
                            <span className="fw--700">
                              GRATIS proefnummer + bol.com cadeaubon
                            </span>
                          </p>
                        </div>
                      </Row>
                      <Row className="d-flex  justify-content-center p-1">
                        <p className="hero-section_Form-col_Check-text mt-n2 ">
                          Check hier uw postcode
                        </p>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        <div className="w-100 h-100">
                          <Input
                            type="text"
                            required 
                            placeholder="1000AA"
                            invalid={checkZipRegex(postCode)}
                            color="black"
                            className="hero-section_Input shadow border-none"
                            maxLength={7}
                            onChange={(e) => {
                              
                              setPostCode(e.target.value);
                            }}
                          />
                          <FormFeedback>Postcode niet correct</FormFeedback>
                        </div>
                      </Row>
                      <Row className="my-md-2  justify-content-center">
                        <div className="d-flex text-center  py-xl-3 py-lg-5 py-3">
                          {checkZipRegex(postCode) === false ? (
                            <>
                              <button
                                type="submit"
                                className="px-4 mt-  py-2  py-md-1 text-center text-white align-self-center justify-content-center btn-yellow rounded-pill fw--600 btn-postcode"
                              >
                                Check mijn postcode
                                <span>
                                  <img
                                    src={rightArrow}
                                    alt="rightArrow"
                                    className="button-image ml-3"
                                  />
                                </span>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="px-4 mt-5  mb-2 py-2  py-md-1 text-center text-white align-self-center justify-content-center btn-yellow rounded-pill fw--600 btn-postcode"
                              >
                                Check mijn postcode
                                <span>
                                  <img
                                    src={rightArrow}
                                    alt="rightArrow"
                                    className="button-image ml-3"
                                  />
                                </span>
                              </button>
                            </>
                          )}
                        </div>
                      </Row>
                    </Container>
                  </Form>
                ) : (
                  <RegisterationForm props={postCode} />
                )}
              </Container>
            </Col>

            <Col xs="6" md="6" className="hero-section_Poster-col">
             

              {active === 1 ? (
                <img
                  src={badge}
                  alt="PersonPicture"
                  className="hero-section_Poster-col_badge d-none d-md-block"
                />
              ) : (
                ""
              )}
              <div className="d-none d-md-block right_img">
                <img
                  src={AdvertisementImage}
                  alt="Advertisement"
                  width="156%"
                  className="right_img_img"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <PromotionBar /> */}
      {active === 1 ? (
        <>
          <Topbar />
          <Row className="row justify-content-center">
            <div className="HeroLeftCol col-md-12 col-lg-6 mb-sm-4 ">
              <h1 className="mb-4 mt-5 pr-2">
                Actie! Probeer MAX Magazine nu GRATIS
              </h1>
              <h2 className="my-4">
                <b className="font-bold d-block">
                  + maak kans op een HEMA cadeaukaart{" "}
                </b>
              </h2>
              <p>
                <b> Controleer hier of uw postcode </b>in aanmerking komt voor
                een GRATIS proefnummer + HEMA cadeaukaart:
              </p>
              {/* <div className="container-lg"> */}
              <Form action="" onSubmit={RegisterationFormData}>
                {/* <Row className="w-100"> */}
                <Card className="d-flex flex-row align-items-center shadow heroCard">
                  <Col sm="6" className="pl-0">
                    {/* <Input placeholder="2233CC" /> */}
                    <div className="w-100 h-100">
                      <Input
                        type="text"
                        className="h-100 py-3"
                        required
                        placeholder="2233CC"
                        invalid={checkZipRegex(postCode)}
                        color="black"
                        // className="hero-section_Input shadow border-none"
                        maxLength={7}
                        onChange={(e) => {
                          setPostCode(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm="6" className="p-0">
                    {" "}
                    <button
                      className="hero-button d-flex flex-nowrap justify-content-center align-items-center text-nowrap py-3"
                      type="submit"
                    >
                      Controleer mijn postcode{" "}
                      <i className="fa fa-solid fa-chevron-right mx-3"></i>
                    </button>
                  </Col>
                </Card>
                {/* </Row> */}
                {checkZipRegex(postCode) && (
                  <small className="text-danger">Postcode niet correct</small>
                )}
              </Form>
            </div>
            {/* </div> */}

            <div className="HeroRightCol col-md-12 col-lg-6 my-md-0   ">
              <svg
                width="90"
                height="90"
                viewBox="0 0 79 79"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 39.3977C0 17.6389 17.6389 0 39.3977 0C61.1564 0 78.7953 17.6389 78.7953 39.3977V74.5817C78.7953 76.9088 76.9088 78.7953 74.5817 78.7953H39.3977C17.6389 78.7953 0 61.1564 0 39.3977Z"
                  fill="#F97304"
                />
                <path
                  d="M11.2321 44.9128V42.7272H13.4752C13.4369 42.3821 13.4177 42.037 13.4177 41.6919C13.4177 41.3276 13.4369 40.9729 13.4752 40.6278H11.2321V38.4134H13.8203C14.3572 36.247 15.4308 34.5119 17.0413 33.2082C18.6709 31.9045 20.7223 31.2526 23.1955 31.2526C25.6112 31.2526 27.5859 31.8566 29.1197 33.0644C30.6726 34.2531 31.6408 35.9306 32.0243 38.0971H27.9694C27.7201 37.0043 27.1833 36.1511 26.3589 35.5376C25.5537 34.9049 24.4896 34.5886 23.1667 34.5886C21.8055 34.5886 20.6552 34.9241 19.7158 35.5951C18.7955 36.2662 18.1149 37.2056 17.6739 38.4134H23.6556V40.6278H17.2138C17.1946 40.9729 17.185 41.3276 17.185 41.6919C17.185 42.0561 17.1946 42.4012 17.2138 42.7272H23.6556V44.9128H17.6739C18.0957 46.1398 18.7763 47.0888 19.7158 47.7598C20.6552 48.4309 21.8055 48.7664 23.1667 48.7664C24.4896 48.7664 25.5537 48.4788 26.3589 47.9036C27.1833 47.3093 27.7201 46.4945 27.9694 45.4592H32.0243C31.6408 47.5298 30.6726 49.1498 29.1197 50.3193C27.5859 51.4888 25.6112 52.0736 23.1955 52.0736C20.7223 52.0736 18.6709 51.4313 17.0413 50.1468C15.4116 48.8431 14.3284 47.0984 13.7916 44.9128H11.2321Z"
                  fill="white"
                />
                <path
                  d="M34.8149 51.7285V49.054C36.042 48.0378 37.2306 47.0313 38.381 46.0344C39.5505 45.0374 40.5858 44.05 41.4868 43.0723C42.4071 42.0945 43.1356 41.1455 43.6725 40.2252C44.2285 39.2858 44.5065 38.3751 44.5065 37.4932C44.5065 36.6688 44.2764 35.9402 43.8163 35.3075C43.3753 34.6749 42.6372 34.3585 41.6019 34.3585C40.5474 34.3585 39.7518 34.7036 39.2149 35.3938C38.6781 36.084 38.4097 36.918 38.4097 37.8958H34.8437C34.882 36.4195 35.208 35.1925 35.8215 34.2147C36.435 33.2178 37.2498 32.4797 38.2659 32.0004C39.282 31.5019 40.4228 31.2526 41.6881 31.2526C43.7396 31.2526 45.3309 31.8182 46.462 32.9494C47.6123 34.0614 48.1875 35.5089 48.1875 37.2919C48.1875 38.4038 47.9287 39.4871 47.411 40.5415C46.9126 41.596 46.2511 42.6121 45.4267 43.5899C44.6023 44.5677 43.7108 45.4879 42.7522 46.3507C41.7936 47.1943 40.8637 47.9803 39.9627 48.7089H48.6764V51.7285H34.8149Z"
                  fill="white"
                />
                <path
                  d="M59.8463 52.0736C57.9866 52.0736 56.3953 51.6422 55.0724 50.7795C53.7495 49.8975 52.7238 48.6801 51.9953 47.1272C51.2859 45.5551 50.9312 43.7337 50.9312 41.6631C50.9312 39.6117 51.2859 37.7999 51.9953 36.2278C52.7238 34.6557 53.7495 33.4383 55.0724 32.5755C56.3953 31.6936 57.9866 31.2526 59.8463 31.2526C61.7059 31.2526 63.2972 31.6936 64.6201 32.5755C65.943 33.4383 66.9591 34.6557 67.6685 36.2278C68.397 37.7999 68.7613 39.6117 68.7613 41.6631C68.7613 43.7337 68.397 45.5551 67.6685 47.1272C66.9591 48.6801 65.943 49.8975 64.6201 50.7795C63.2972 51.6422 61.7059 52.0736 59.8463 52.0736ZM59.8463 48.7664C61.3609 48.7664 62.5975 48.1337 63.5561 46.8683C64.5147 45.603 64.994 43.8679 64.994 41.6631C64.994 39.4583 64.5147 37.7232 63.5561 36.4579C62.5975 35.1925 61.3609 34.5598 59.8463 34.5598C58.3125 34.5598 57.0663 35.1925 56.1077 36.4579C55.1683 37.7232 54.6985 39.4583 54.6985 41.6631C54.6985 43.8679 55.1683 45.603 56.1077 46.8683C57.0663 48.1337 58.3125 48.7664 59.8463 48.7664Z"
                  fill="white"
                />
                <path
                  d="M32.1632 25.0452C31.6399 25.0452 31.2413 24.9019 30.9672 24.6154C30.6993 24.3226 30.5654 23.9177 30.5654 23.4006V21.4384H29.9113V20.4198H30.5654V19.0743H31.9763V20.4198H33.0322V21.4384H31.9763V23.2138C31.9763 23.4754 32.0262 23.6654 32.1258 23.7837C32.2255 23.8959 32.403 23.9519 32.6585 23.9519C32.864 23.9519 33.0603 23.9021 33.2471 23.8024V24.8583C32.9606 24.9829 32.5993 25.0452 32.1632 25.0452Z"
                  fill="white"
                />
                <path
                  d="M34.2443 24.9051C34.0948 24.9051 33.9671 24.8552 33.8612 24.7555C33.7616 24.6559 33.7117 24.5313 33.7117 24.3818C33.7117 24.2323 33.7647 24.1046 33.8706 23.9987C33.9765 23.8928 34.1011 23.8398 34.2443 23.8398C34.3938 23.8398 34.5216 23.8928 34.6275 23.9987C34.7334 24.1046 34.7863 24.2323 34.7863 24.3818C34.7863 24.5313 34.7334 24.6559 34.6275 24.7555C34.5216 24.8552 34.3938 24.9051 34.2443 24.9051Z"
                  fill="white"
                />
                <path
                  d="M35.3816 20.4198H36.7926L37.6149 23.2231L38.4185 20.4198H39.5959L40.4088 23.2231L41.2218 20.4198H42.6327L41.147 24.9051H39.8668L39.0072 22.1205L38.1475 24.9051H36.8674L35.3816 20.4198Z"
                  fill="white"
                />
                <path
                  d="M43.771 24.9051C43.6215 24.9051 43.4938 24.8552 43.3879 24.7555C43.2883 24.6559 43.2384 24.5313 43.2384 24.3818C43.2384 24.2323 43.2914 24.1046 43.3973 23.9987C43.5032 23.8928 43.6278 23.8398 43.771 23.8398C43.9205 23.8398 44.0483 23.8928 44.1542 23.9987C44.2601 24.1046 44.313 24.2323 44.313 24.3818C44.313 24.5313 44.2601 24.6559 44.1542 24.7555C44.0483 24.8552 43.9205 24.9051 43.771 24.9051Z"
                  fill="white"
                />
                <path
                  d="M44.9831 20.4198H46.1698L47.3845 23.5221L48.5993 20.4198H49.786L47.9359 24.9051H46.8332L44.9831 20.4198Z"
                  fill="white"
                />
                <path
                  d="M51.1077 24.9051C50.9582 24.9051 50.8305 24.8552 50.7246 24.7555C50.6249 24.6559 50.5751 24.5313 50.5751 24.3818C50.5751 24.2323 50.628 24.1046 50.7339 23.9987C50.8398 23.8928 50.9644 23.8398 51.1077 23.8398C51.2572 23.8398 51.3849 23.8928 51.4908 23.9987C51.5967 24.1046 51.6497 24.2323 51.6497 24.3818C51.6497 24.5313 51.5967 24.6559 51.4908 24.7555C51.3849 24.8552 51.2572 24.9051 51.1077 24.9051Z"
                  fill="white"
                />
              </svg>

              <img
                src={image}
                alt="img"
                className=" desktop d-md-block d-none d-lg-none"
              />
              <img
                src={image2}
                alt="img"
                className=" desktop d-lg-block d-none"
              />
              <img
                src={image3}
                alt="img"
                className="img-fluid mobile d-md-none d-block"
              />
            </div>
          </Row>

          <MainViewContent1 />
        </>
      ) : (
        <RegisterationForm props={postCode} />
      )}
    </>
  );
}

export default HeroSection;
