import StreetRepository from "./StreetRepository";
import postFormRepostiory from "./postFormRepostiory";
import postFormRepostiory2 from "./postFormRepostiory2";
const LEADS = "leads";

export default {
  // getStreet(payload) {
  // 	// console.log(payload, 'payload in Repostory');
  // 	return StreetRepository.get(
  // 		`?postcode=${payload.zip}&number=${payload.house_number}`
  // 	);
  // },

  getStreet(payload) {
    return StreetRepository.get(
      `?authKey=P6JTU52clKYjZca8&postalCode=${payload.zip}&streetNumberAndPremise=${payload.house_number}`
    );
  },

  postLead(payload) {
    // console.log(payload, 'payload repository last');
    return postFormRepostiory.post(`${LEADS}`, payload);
  },
  postLead2(payload) {
    // console.log(payload, 'payload repository last');
    return postFormRepostiory2.post(`${LEADS}`, payload);
  },
};
