import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Footer = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <>
      <hr className="footerLine d-md-flex d-none  mb-0 mt-5" />
      <div className="px-4 py-md-4 py-5">
        <div className="d-flex justify-content-between align-items-center footer1">
          <h6 className="mb-0">© MAX Magazine 2024</h6>
          <h6 className="textUnderline pointer mb-0" onClick={toggle}>
            Privacy statement
          </h6>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalBody className="m-2">
          <h3>Privacyverklaring</h3>
          <p>
            Uw privacy is voor MAX Magazine van groot belang. Wij houden ons dan
            ook aan de privacywet (de Algemene verordening gegevensbescherming.
            Hierna ook de “AVG”). Dit betekent dat uw gegevens veilig bij ons
            zijn en dat wij ze altijd netjes gebruiken.
          </p>
          <h3>In het Kort</h3>
          <p>
            Bij het gebruik van uw gegevens houden wij ons aan de eisen die de
            privacywetgeving stelt. Dat betekent onder andere dat:
          </p>
          <ul>
            <li>
              wij <b> duidelijk vermelden met welke doeleinden</b> wij
              persoonsgegevens gebruiken. Dat doen wij via deze
              privacyverklaring;
            </li>
            <li>
              wij onze <b> verzameling van persoonsgegevens </b>
              beperken tot alleen de persoonsgegevens die nodig zijn voor
              legitieme doeleinden;
            </li>
            <li>
              wij u eerst <b> vragen om uitdrukkelijke toestemming </b> om uw
              persoonsgegevens te verwerken in gevallen waarin uw toestemming is
              vereist;
            </li>
            <li>
              wij <b> passende beveiligingsmaatregelen nemen</b> om uw
              persoonsgegevens te beschermen en dat ook eisen van partijen die
              in onze opdracht persoonsgegevens verwerken;
            </li>
            <li>
              wij uw recht respecteren om uw persoonsgegevens op aanvraag onder
              andere ter inzage te bieden, te corrigeren of te verwijderen.
            </li>
          </ul>
          <p>
            In deze privacyverklaring leggen we uit wat we bij MAX Magazine doen
            met de informatie die u aan ons geeft of die wij verzamelen. Deze
            privacyverklaring is voor het laatst aangepast op 22 augustus 2022.
          </p>
          <h3>Gebruik van persoonsgegevens</h3>
          <p>
            Bij het gebruiken van onze websites en diensten is het mogelijk dat
            wij de volgende informatie van en over u verkrijgen:
          </p>
          <ul>
            <li>Uw naam en adres*</li>
            <li>Telefoonnummer*</li>
            <li>E-mailadres*</li>
            <li>Geboortedatum*</li>
            <li>Geslacht*</li>
            <li>Apparaat gegevens/IP-adres (cookies)</li>
            <li>Internetgedrag (cookies)</li>
            <li>Factuuradres</li>
          </ul>
          <p>
            Bovenstaande categorieën informatie met toevoeging (*) kunnen ook
            toebehoren aan een persoon aan wie u een abonnement op MAX Magazine
            cadeau doet. De wet noemt de bovenstaande gegevens
            “persoonsgegevens” en zegt dat we die alleen mogen gebruiken als we
            precies uitleggen waarom en met welke reden. Dit leest u hieronder.
            De van u ontvangen persoonlijke gegevens kunnen wij voor
            verschillende doelen gebruiken:
          </p>
          <ul>
            <li>
              Het afsluiten van een abonnement op MAX Magazine voor uzelf, of
              voor iemand anders;
            </li>
            <li>Het versturen van de MAX Magazine nieuwsbrief;</li>
            <li>Het beantwoorden van uw vragen of het vragen om uw mening;</li>
            <li>Het meedoen aan acties, prijsvragen en spellen</li>
            <li>Het verbeteren van onze website;</li>
            <li>
              Het na uw toestemming tonen van gepersonaliseerde advertenties en
              het meten van de effecten van deze advertenties.
            </li>
          </ul>
          <p>
            In juridische zin betekent dit dat wij uw gegevens gebruiken op uw
            verzoek, wanneer wij een overeenkomst met u hebben, wanneer wij hier
            een gerechtvaardigd belang voor hebben of wanneer wij dit op basis
            van de wet verplicht zijn om te doen.
          </p>
          <h3>Gegevens die wij ontvangen van derden</h3>
          <p>
            Naast dat wij uw gegevens rechtstreeks van u ontvangen wanneer u een
            abonnement neemt op MAX Magazine, kan het ook zijn dat wij uw
            gegevens van derden ontvangen.
          </p>
          <p>
            Ook op deze mogelijke verwerking van uw persoonsgegevens is de rest
            van deze privacyverklaring natuurlijk gewoon van toepassing.
          </p>
          <p>
            <b>Beware</b> Wij bewaren uw gegevens zolang als dat nodig is om te
            voorzien in de hierboven genoemde doelen, zoals om u gebruik te
            laten maken van uw abonnement. Wanneer wij de gegevens niet meer
            nodig hebben worden deze gewist, tenzij wij een wettelijke plicht
            hebben om de gegevens langer te bewaren
          </p>
          <h3>Verstrekking aan andere bedrijven of instellingen</h3>
          <p>
            Wij geven uw persoonsgegevens alleen aan andere bedrijven of
            instellingen als dat nodig is voor het functioneren van onze
            websites en/of diensten. Denk hierbij aan een postbedrijf dat wij
            inschakelen om ons magazine naar onze leden te versturen. Daarnaast
            is het mogelijk dat wij uw persoonsgegevens moeten afstaan indien
            dat wettelijk verplicht is (bijvoorbeeld wanneer dit door de politie
            wordt gevorderd). Ook kunnen wij uw gegevens delen met andere
            bedrijven of instellingen in het geval u hiervoor toestemming heeft
            gegeven. Wij zullen uw persoonsgegevens nooit verkopen aan andere
            bedrijven of instellingen voor commerciële doeleinden van derden.
            Voor het voeren van een gezamenlijke administratie is het mogelijk
            dat wij, wanneer noodzakelijk, uw gegevens delen met
            Omroepvereniging MAX. Dit doen wij omdat wij hiervoor een
            gerechtvaardigd belang hebben.
          </p>
          <h3>Cookies</h3>
          <p>
            Onze websites gebruiken cookies. Cookies zijn kleine bestanden waar
            we informatie in kunnen opslaan zodat u die niet steeds hoeft in te
            vullen. Maar we kunnen er ook mee zien dat u ons weer bezoekt. In
            onze
            <span className="textUnderline"> cookieverklaring</span> kunt u hier
            meer over lezen.
          </p>
          <h3>Beveiliging</h3>
          <p>
            Beveiliging van persoonsgegevens is voor ons van groot belang. Wij
            nemen beveiligingsmaatregelen om misbruik van en ongeautoriseerde
            toegang tot persoonsgegevens te beperken. Wij maken gebruik van
            beveiligde netwerkverbindingen. Dat wil zeggen dat de verbinding
            tussen u en ons beveiligd is. Gegevens die tussen uw en onze
            systemen verstuurd worden, blijven vertrouwelijk.
          </p>
          <p>
            <b>Wijzigingen in deze privacyverklaring</b>
            Wanneer onze websites en/of diensten wijzigen, moeten wij natuurlijk
            ook de privacyverklaring aanpassen. Let dus altijd op de datum
            hierboven en kijk regelmatig of er nieuwe versies zijn.
          </p>
          <p>
            <b>Inzage en wijzigen van uw gegeven</b>
            sAls u vragen hebt of wilt weten welke persoonsgegevens wij van u
            hebben, kunt u altijd contact met ons opnemen. Zie de
            contactgegevens hieronder.
          </p>
          <ul>
            <li>
              uitleg krijgen over welke persoonsgegevens we van u hebben en wat
              we daarmee doen;
            </li>
            <li>inzage in de persoonsgegevens die we van u hebben;</li>
            <li>het laten corrigeren van fouten;</li>
            <li>het laten verwijderen van verouderde persoonsgegevens;</li>
            <li>het verplaatsen van uw gegevens;</li>
            <li>intrekken van toestemming;</li>
            <li>bezwaar maken tegen een bepaald gebruik.</li>
          </ul>
          <p>
            Let op dat u altijd duidelijk aangeeft wie u bent, zodat we zeker
            weten dat we geen gegevens van de verkeerde persoon aanpassen of
            verwijderen.
          </p>
          <h3>Klacht indienen</h3>
          <p>
            Wij helpen u graag als u natuurlijk graag bij vragen over het
            gebruik van uw persoonsgegevens door MAX Magazine. U kunt contact
            met ons opnemen via de onderstaande contactgegevens. Als u vindt dat
            wij u niet op de juiste manier helpen, dan heeft u het recht om een
            klacht in te dienen bij de Autoriteit Persoonsgegevens.
          </p>
          <br />
          <p className="my-0">Contactgegevens</p>
          <p className="my-0">MAX Magazine</p>
          <p className="my-0">Media Park</p>
          <p className="my-0">Joop van den Endeplein 9</p>
          <p className="my-0">1217 WJ Hilversum</p>
          <p className="my-0">
            telefoonnummer: 035-2019505 (Klantenservice MAX Magazine)
          </p>
          <p>
            Onze functionaris gegevensbescherming is te bereiken via
            <span className="textUnderline"> privacy@omroepmax.nl</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Ok
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Footer;
