import React, { useEffect, useState } from "react";
import { Container, Col, Row, Card } from "reactstrap";
import badge2 from "../../assets/img/Badge.png";
import badge2sm from "../../assets/img/Badge.png";
// import iVector from "../assets/img/i-vector.png";
// import Arrow from "../../assets/img/Arrow.svg";
import badgeBol from "../../assets/img/blueBadge.png";

import { stepData } from "../../helpers/stepData";
import FormReg from "../Form";

function UITwo({ step, setStep }) {
  const [questionsData, setQuestionsData] = useState();
  const [isChecked, setIsChecked] = useState("");
  const [loadForm, setLoadForm] = useState(false);
  const [questionSection, setQuestionSection] = useState(true);
  let year = new Date().getFullYear();
  useEffect(() => {
    setQuestionsData(stepData[0]);
  }, []);
  useEffect(() => {
    // Define a timer variable
    let timer;

    // Define a function to update questionsData after a delay
    const updateQuestionsData = () => {
      if (questionsData?.number < 4) {
        setQuestionsData(stepData[questionsData?.number]);
        setIsChecked("");
      } else {
        setLoadForm(true);
        setQuestionSection(false);
        setStep(3);
      }
    };

    // If isChecked changes, reset the timer and start a new one
    if (isChecked) {
      clearTimeout(timer); // Reset the timer
      timer = setTimeout(updateQuestionsData, 1000); // Start a new timer after 2 seconds
    }

    // Clean up the timer when the component unmounts or when isChecked changes
    return () => {
      clearTimeout(timer);
    };
  }, [isChecked]);
  // console.log(loadForm, "loadForm");
  return (
    <Container fluid className="ajust-height ">
      <Row
        className={`hero-contents${step === 3 ? " hero-contents__img " : ""}`}
      >
        {questionSection && (
          <Col
            md={8}
            lg={8}
            className=" h-auto  px-0 hero-contents__bg d-flex flex-column align-items-center"
          >
            <Row className="w-100 mt-md-4 mt-0">
              <Col
                md={3}
                className="px-0 mx-0 my-0 py-0 hero-contents__textdiv"
              ></Col>
              <Col className="px-md-0 pl-5 mr-md-5 mr-0 my-md-0 mb-4 py-3 hero-contents__text ">
                {/* <p className=" py-3 mr-5   fw--700"> */}
                Landelijk TV Onderzoek {year}
                {/* </p> */}
              </Col>
            </Row>
            <Row className="mt-2 h-100 w-100 align-items-center">
              <Col md={3}></Col>
              <Col className="px-md-0 px-5">
                <Card className="card-styling shahow-sm p-5 text-site-secondary bg-whit">
                  <spna>
                    Vraag {questionsData?.number} van {stepData?.length}
                  </spna>
                  <h2 className="mb-0 fw--800 text-left">
                    {questionsData?.question}
                  </h2>
                  <span className="mt-n1 fw--600 mb-4">
                    {questionsData?.subtitle ? questionsData?.subtitle : ""}
                  </span>
                  {questionsData?.option?.map((opt, index) => (
                    <div
                      key={index}
                      className={`rounded w-75 p-3 d-flex align-items-center mt-2 cursor-pointer ${
                        isChecked == opt
                          ? "bg-secondary text-white"
                          : "bg-site-gray"
                      }`}
                      onClick={() => {
                        setIsChecked(opt);
                      }}
                    >
                      <input type="radio" checked={isChecked == opt} />
                      <h6 className="mb-0 ml-3">{opt}</h6>
                    </div>
                  ))}
                </Card>
                <img
                  src={badge2sm}
                  alt=""
                  className="badgeBig d-md-none d-flex"
                />
              </Col>
              <Col md={1}></Col>
            </Row>
            <div className="bottom-bar mt-auto">
              <Row className="">
                <Col md={3}>
                  <img src={badgeBol} className="badgeBol d-md-block d-none" />
                </Col>
                <Col md={7} className="py-md-2 py-3 d-flex px-4">
                  <span className="fw--700 mb-0 fs--13 px-md-0 pl-5 pr-0">
                    Naast het gegarandeerde gratis proefnummer, maakt iedere
                    deelnemer ook nog kans op een bol.com cadeaukaart t.w.v
                    €20,-
                  </span>
                  <img src={badgeBol} className="badgeBol d-md-none d-block" />
                </Col>
                <Col md={2}></Col>
              </Row>
            </div>
          </Col>
        )}
        {loadForm && <FormReg />}
        {/* <Form /> */}
        <Col md={4} lg={4} className="px-0 d-md-block d-none py-0 ">
          {questionSection && (
            <div className="h-100 w-100 d-flex  justify-content-center">
              <img src={badge2} alt="" className="badgeBig " />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
export default UITwo;
