export const stepData = [
  {
    number: 1,
    question: "Kijkt u lineair TV?",
    subtitle:
      "Hiermee bedoelen we dat u kijkt op het moment dat het wordt uitgezonden.",
    option: ["Ja", "Nee"],
  },
  {
    number: 2,
    question: "Hoe vaak kijkt u lineair TV?",
    subtitle: "",

    option: ["Dagelijks", "1-4 keer per week", "Maandelijks", "(Bijna) nooit"],
  },
  {
    number: 3,
    question:
      "Hoe vaak kijkt u naar programma’s van streaming diensten (bijvoorbeeld Netflix of Videoland)?",
    subtitle: "",

    option: ["Dagelijks", "1-4 keer per week", "Maandelijks", "(Bijna) nooit"],
  },
  {
    number: 4,
    question: "Wat voor programma’s kijkt u het liefst?",
    subtitle: "",
    option: ["Nieuws/actualiteiten", "Entertainment", "Beide"],
  },
  // {
  //   number: 4,
  //   question: "Heeft u wel eens gebruik gemaakt van een TV-gids?",
  //   subtitle: "",
  //   option: ["Ja, nog steeds", "Ja, vroeger", "Nooit gebruik van gemaakt"],
  // },
];
